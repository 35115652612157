<template>
  <CCard>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <tpAccessModal
      :modal.sync="modal"
      :AccessItems="AccessItems"
      :edit="edit"
      :title="titleModal"
      @submited="handleSubmit"
      @close="closeModal"
    />
    <CCardHeader class="text-center bg-dark text-white">
        <b>{{$t('label.accessTypeList')}} </b>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12" class="d-flex justify-content-end">
            <CButton
              color="add"
              class="d-flex align-items-center"
              v-c-tooltip="{
                content: $t('label.accessType'),
                placement: 'top-end'
              }"
              @click="toggleAdd"
            >
              <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
            </CButton>
          </CCol>
          <CCol sm="12">
            <dataTableExtended
              class="align-center-row-datatable"
              :items="formatedItems"
              :fields="fields"
              column-filter
              :noItemsView="tableText.noItemsViewText"
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              :items-per-page="tableText.itemsPerPage"
              hover
              small
              sorter
              pagination
            >
              <template #Status="{ item }">
                <td class="text-center align-middle">
                  <CBadge :color="getBadge(item.Status)">
                    {{$t('label.'+item.Status)}}
                  </CBadge>
                </td>
              </template>
              <template #Detalle="{ item }">
                <td class="text-center">
                  <CButton
                    color="edit"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{
                      content: $t('label.edit')+' '+$t('label.accessType'),
                      placement: 'top-end'
                    }"
                    @click="toggleEdit(item)"
                  >
                  <CIcon name="pencil" />
                </CButton>
                </td>
              </template>
            </dataTableExtended>
          </CCol>
        </CRow>
    </CCardBody>
  </CCard>
</template>
  
<script>
import { DateFormater } from "@/_helpers/funciones";
import General from "@/_mixins/general";
import tpAccessModal from "./tpAccess-modal";

//DATA
function data() {
  return {
    items: [],
    Loading:  false,
    modal: false,
    AreaId: '',
    AccessItems: {},
    edit: false,
    titleModal: '',
  };
}

//METHOD
function getListTpAccess() {
  this.Loading = true;
  this.$http
    .get('TpAccess-list')
    .then(response => {
      let listado = response.data.data;
      if (listado && listado.length != 0){
        this.items = listado;
      }
    })        
    .finally(() => {
      this.Loading = false;
    });
}

function toggleAdd() {
  this.modal = true;
  this.edit = false;
  this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.accessType');
}
function toggleEdit(item) {
  let _lang = this.$i18n.locale;
  this.AccessItems = item;
  this.edit = true;
  this.modal = true;
  let title = _lang=='en' ? this.AccessItems.TpAccessNameEn : this.AccessItems.TpAccessNameEs;
  this.titleModal= this.$t('label.edit')+' '+this.$t('label.accessType')+': '+title;
}
function closeModal(){
  this.modal = false;
  this.edit = false;
  this.titleModal = '';
}
function handleSubmit() {
  this.getListTpAccess();
}

// COMPUTED
function fields() {
  return [
    {
      key: "Nro",
      label: "#",
      _style: "width:1%;",
      filter: false,
    },
    { key: "TpAccessNameEs", label: this.$t("label.accessType")+' '+('(ES)'),_styles: "width: 25%;",_classes:'text-center',},
    { key: "TpAccessNameEn", label: this.$t("label.accessType")+' '+('(EN)'),_styles: "width: 25%;",_classes:'text-center',},
    { key: "Usuario", label: this.$t("label.user"), _styles: "width: 10%;",_classes: "text-center" },
    { key: "FormatedDate", label: this.$t("label.date"), _styles: "width: 10%;",_classes: "text-center" },
    { key: "Status", label: this.$t("label.status"),_styles: "width: 10%;", _classes: "text-center" },
    {
      key: "Detalle",
      label: "",
      sorter: false,
      filter: false,
      _styles: "min-width: 45px;",
    },
  ];
}
function formatedItems() {
  return this.items.map((items) =>
    Object.assign({}, items, {  
      Nro: items.Nro,
      Usuario: items.TransaLogin ? items.TransaLogin : "N/A",
      Status: items.Status,
      FormatedDate: items.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(items.TransaRegDate) : 'N/A',
      _classes: items.Status === "INACTIVO" ? "table-danger" : "",
    })
  );
}

export default {
  name: 'index',
  mixins: [General],
  props: {Tab: Number},
  data,
  components: {
    tpAccessModal,
  },
  mounted() {
    this.getListTpAccess();
  },
  methods: {
    getListTpAccess,
    toggleAdd,
    toggleEdit,
    handleSubmit,
    closeModal,
  },
  computed: {
    fields,
    formatedItems,
  },
};
</script>